<template>
    <two-cards>
        <template #header>
            <h4 class="card-title">Filtros</h4>
        </template>
        <template #one>
            <form @submit.prevent="pesquisar">
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <label for="i_nome">Gerência Geral *</label>
                        <vue-multi-select class="multi-100" :disabled="loading" v-model="gerenciaGeral" search historyButton :filters="multiSelectFilters[0]" :options="multiSelectOptions" :selectOptions="gerenciaGeralOptions" @input="getGerencia" data-cy="Gerência Geral" :btnLabel="() => customLabel(gerenciaGeral)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_a_pai">Gerência</label>
                        <vue-multi-select class="multi-100" :disabled="!gerenciaGeral.length" v-model="gerencia" search historyButton :filters="multiSelectFilters[1]" :options="multiSelectOptions" :selectOptions="gerenciaOptions" @input="getArea" data-cy="Gerência" :btnLabel="() => customLabel(gerencia)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_a_pai">Áreas</label>
                        <vue-multi-select class="multi-100" :disabled="!gerencia.length" v-model="areas" search historyButton :filters="multiSelectFilters[2]" :options="multiSelectOptions" :selectOptions="areaOptions" @input="getSubArea" data-cy="Áreas" :btnLabel="() => customLabel(areas)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_a_pai">Subáreas</label>
                        <vue-multi-select class="multi-100" :disabled="!areas.length" v-model="subAreas" search historyButton :filters="multiSelectFilters[3]" :options="multiSelectOptions" :selectOptions="subAreaOptions" @input="getDetectores" data-cy="Subáreas" :btnLabel="() => customLabel(subAreas)"/>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="i_nome">Detectores *</label>
                        <vue-multi-select class="multi-100" :disabled="loading || !gerenciaGeral.length" v-model="detectores" search historyButton :filters="multiSelectFilters[4]" :options="multiSelectOptions" :selectOptions="detectorOptions" data-cy="Detectores" :btnLabel="() => customLabel(detectores)"/>
                    </div>
                    <div class="col form-group col-md-4">
                        <label for="data-inicial">Data inicial *</label>
                        <datepicker placeholder="Início" id="data-inicial" input-class="bg-white" v-model="filtros.inicio" @selected="filtroRapido(FILTROS_RAPIDOS[0])" :format="formatter" :language="ptBR" :bootstrap-styling="true" data-cy="Data Inicial"></datepicker>
                    </div>
                    <div class="col form-group col-md-4">
                        <label for="data-final">Data final *</label>
                        <datepicker placeholder="Fim" id="data-final" input-class="bg-white" v-model="filtros.fim" @selected="filtroRapido(FILTROS_RAPIDOS[0])" :format="formatter" :language="ptBR" :bootstrap-styling="true" data-cy="Data Final"></datepicker>
                    </div>
                </div>
                <div class="form-row mt-2">
                    <div class="col-lg-2 col-md-12 mb-1" style="font-size: 1.5rem">
                        Filtros rápidos:
                    </div>
                    <div class="col-lg-10 col-md-12">
                        <button type="button" class="col-md-2 btn rounded-pill mr-3 mb-1" v-for="(f, idx) in FILTROS_RAPIDOS" :key="idx" @click="btnFiltroRapido(f)" :class="filtros.rapido === f ? 'btn-info' : 'btn-outline-dark'" :data-cy="f.nome">{{f.nome}}</button>
                    </div>
                </div>
                <p class="small text-right mt-2 mb-0">Campos marcados com * são obrigatórios.</p>
                <div class="d-flex justify-content-end tcs-card-footer-action">
                    <button :disabled="!detectores.length || dataLoading" class="btn btn-success tcs-btn" type="submit" data-cy="Pesquisar"><SearchIcon /></button>
                </div>
            </form>
        </template>
        <template #two>
            <tbody v-if="dataLoading">
                <tb-skeleton shape="rect" style="background-color: #dcdcdc; height: 25rem; width: 100%" theme="opacity"></tb-skeleton>
            </tbody>
            <template v-else>
                <ECharts autoresize v-if="ready" :options="options" />
                <p class="text-center my-auto" v-else> Selecione os detectores que deseja visualizar </p>
            </template>
        </template>
    </two-cards>
</template>

<script>
import TwoCards from "../templates/TwoCards"
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import axios from 'axios'
import api from '@/api.js'

import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

import VueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

import ECharts from 'vue-echarts'
import optionsFactory from '../charts/timeseries.echarts'

const filters = () => [{
    nameAll: 'Selecionar todas',
    nameNotAll: 'Deselecionar todas',
    func() {
        return true;
    },
}];

export default {
    components: {
        TwoCards,
        Datepicker,
        VueMultiSelect,
        ECharts,
    },
    data() {
        return {
            ptBR,
            gerenciaGeral: [],
            gerencia: [],
            areas: [],
            subAreas: [],
            detectores: [],

            gerenciaGeralOptions: [],
            gerenciaOptions: [],
            areaOptions: [],
            subAreaOptions: [],
            detectorOptions: [],
            allAreas: [],

            multiSelectFilters: Array.apply(null, Array(5)).map(() => filters()),
            multiSelectOptions: {
                multi: true,
            },
            options: optionsFactory('Tendência Histórica', [], []),
            ready: false,

            FILTROS_RAPIDOS: [{
                    nome: 'Personalizado',
                },{
                    nome: 'Último dia',
                    inicio: [-1, 'day'],
                    fim: [0, 'days'],
                    agrupamento: 'Hora'
                }, {
                    nome: 'Última semana',
                    inicio: [-1, 'week'],
                    fim: [0, 'weeks'],
                    agrupamento: 'Hora'
                }, {
                    nome: 'Último mês',
                    inicio: [-1, 'month'],
                    fim: [0, 'months'],
                    agrupamento: 'Dia'
                }],
            filtros: {
                inicio: null,
                fim: null,
                agrupamento: null,
                rapido: 0
            },
            errMsg: '',
            loading: true,
            showMarkLine: true,
            graphData: [],
            dataLoading: false,
        }
    },
    mounted() {
            this.filtroRapido(this.FILTROS_RAPIDOS[1]);
            this.getGerenciaGeral();
            this.showGrafico = false;
        },
    methods: {
        toggleMarkLine() {
            this.showMarkLine = !this.showMarkLine;
        },
        customLabel(selected) {
            return selected.length ? `${selected.length} selecionado${selected.length > 1 ? 's' : ''}` : 'Selecione';
        },
        formatter (date) {
            return dayjs(date).locale('pt-br').format('D [de] MMMM [de] YYYY');
        },
        getGerenciaGeral() {
            this.loading = true;
            return axios.get(api.v1.area.list(1, -1)).then(res => {
                this.allAreas = res.data.rows;
                this.gerenciaGeralOptions = res.data.rows.filter(el => el.idAreaPai === null).map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });
        },
        async getGerencia() {
            if(this.gerenciaGeral.length === 0) {
                //reseta campos
                this.gerencia = [];
                this.areas = [];
                this.subAreas = [];
                this.areaOptions = [];
                this.subAreaOptions = [];
            }
            else await this.getDetectores();
            this.gerenciaOptions = this.allAreas.filter(el => this.gerenciaGeral.some(e => e.id === el.idAreaPai)).map(el => ({ name: el.nome, id: el.id }));

        },
        async getArea() {
            if(this.gerencia.length === 0) {
                //reseta campos
                this.areas = [];
                this.subAreas = [];
                this.subAreaOptions = [];
            }
            else await this.getDetectores();
            this.areaOptions = this.allAreas.filter(el => this.gerencia.some(e => e.id === el.idAreaPai)).map(el => ({ name: el.nome, id: el.id }));
        },
        async getSubArea() {
            if(this.areas.length === 0) {
                //reseta campos
                this.subAreas = [];
            }
            else await this.getDetectores();
            this.subAreaOptions = this.allAreas.filter(el => this.areas.some(e => e.id === el.idAreaPai)).map(el => ({ name: el.nome, id: el.id }));
        },
        getDetectores() {
            //reseta campo
            this.detectores = [];

            this.loading = true;
            let areas = [];
            //verifica o nivel de filtros de area
            if (this.subAreas.length > 0) {
                areas = this.subAreas.map(el => el.id);
            } else if (this.areas.length > 0) {
                areas = this.areas.map(el => el.id);
            } else if (this.gerencia.length > 0) {
                areas = this.gerencia.map(el => el.id)
            } else if (this.gerenciaGeral.length > 0) {
                areas = this.gerenciaGeral.map(el => el.id);
            }
            return axios.get(api.v1.detector.listByAreas(areas)).then(res => {
                this.detectorOptions = res.data.map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });

        },
        filtroRapido (fr) {
            this.filtros.rapido = fr;
            if (fr.inicio) this.filtros.inicio = dayjs().add(...fr.inicio).toDate();
            if (fr.fim) this.filtros.fim = dayjs().add(...fr.fim).toDate();
            if (fr.agrupamento) this.filtros.agrupamento = fr.agrupamento;
        },
        btnFiltroRapido (fr) {
            if (this.filtros.rapido !== fr) this.filtroRapido(fr);
        },
        async pesquisar() {
            if (this.detectores.length > 10 || this.filtros.fim - this.filtros.inicio > 32*24*60*60*1000) {
                this.$swal.fire({
                    title: 'Atenção',
                    text: 'A busca deve ter no máximo 10 detectores e 1 mês de pesquisa',
                    type: 'warning',
                    focusCancel: true,
                    confirmButtonColor: '#dc3545',
                    confirmButtonText: 'Fechar',
                    allowOutsideClick: true,
                });
                return;
            }

            try {
                this.dataLoading = true;

                let idDetectores = this.detectores.map(d => d.id);

                const nomeTabela = (this.filtros.rapido.nome.split(" ").pop()).replace("ê","e"); // trata o nome do filtro rápido, ex: 'Último mês' => 'mes'

                let { data } = await axios.get(api.v1.graficos.tendencia(
                    this.filtros.inicio,
                    this.filtros.fim,
                    idDetectores,
                    undefined,
                    300,
                    nomeTabela,
                ));

                this.graphData = data;

                this.dataLoading = false;
                this.buildChart();
            } catch (err) {
                this.$swal({
                    title: 'Falha ao fazer a busca!',
                    html: `<p>${err.response.data.error}</p>`,
                    confirmButtonText: 'Fechar',
                    confirmButtonColor: '#6c757d',
                });
            }

        },
        buildChart() {
            this.options = optionsFactory(
                'Tendência Histórica',
                this.graphData.map(d => d.nome),
                this.graphData.map(d => {
                    const markLineData = [];
                    if (d.alerta) markLineData.push({
                        name: d.nome,
                        yAxis: d.alerta,
                        lineStyle: {
                            color: '#dd8d00',
                            width: 2,
                            type: 'solid',
                            opacity: 0.85,
                        },
                        label: {
                            formatter: '{c}',
                            distance: 10,
                            emphasis: {
                                formatter: '{b}',
                            }
                        }
                    });
                    if (d.alarme) markLineData.push({
                        name: d.nome,
                        yAxis: d.alarme,
                        lineStyle: {
                            color: '#d32f2f',
                            width: 2,
                            type: 'solid',
                            opacity: 0.85,
                        },
                        label: {
                            formatter: '{c}',
                            distance: 10,
                            emphasis: {
                                formatter: '{b}',
                            }
                        }
                    });

                    return {
                        name: d.nome,
                        type: 'line',
                        symbol: 'roundRect',
                        symbolSize: 4,
                        hoverAnimation: true,
                        data: d.medicoes.map(m => ({
                            name: m.dataHora,
                            value: [
                                m.dataHora,
                                m.valor,
                            ],
                        })),
                        markLine: markLineData.length > 0 && this.showMarkLine ? {
                            symbol: 'none',
                            data: markLineData.filter((data, index, self) =>
                                self.findIndex(d => d.yAxis === data.yAxis) === index
                            ),
                        } : undefined,
                    }
                }),
                'f',
                this.toggleMarkLine,
            );
            this.ready = true;
        }
    },
    watch: {
        showMarkLine: async function () {
            await this.buildChart();
        },
    }
}
</script>
<style scoped>
    label {
        display: block;
    }
    .echarts {
        width: 100%;
    }
</style>
